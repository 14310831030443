textarea {
  min-height: 50px;
}

.border-wrapper {
  border: 1px solid black;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 10px;
  .input-wrapper {
    padding: 10px;
    height: 100%;
    border-radius: 5px;
  }
}

.margin-top {
  margin-top: 15px;
}

.padding-top {
  padding-top: 15px;
}

.grayed-out {
  color: lightgray;
  label {
    color: lightgray !important;
  }
}

mat-panel-title,
mat-panel-description,
p,
li {
  font-weight: bold;
}

ol li {
  padding: 5px;
}
.pdf-header {
  display: flex;
  align-items: center;
  z-index: 3;
  background-color: #100e17;
  height: 65px;
  width: 100%;
  opacity: 0.95;
  box-shadow: 0 4px 3px rgba(0, 0, 0, 0.5);
  -webkit-print-color-adjust: exact;
  .logo {
    margin-left: 20px;
  }
}

.col-md-6 {
  margin-bottom: 12px;
}

.border-wrapper {
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

label {
  margin-right: 7px;
  font-size: 15px;
}

.text-md {
  font-size: 18px;
}

.bold {
  font-weight: bold;
}

.input-wrapper {
  border-radius: 5px;
}

.label-wrapper {
  padding: 5px;
  border-radius: 5px;
  margin-left: 5px;
}

#fields-input {
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
  text-align: center;
}

.mat-expansion-panel-header[aria-disabled='true'] {
  color: black;
}

.mat-expansion-panel-header[aria-disabled='true']:hover {
  background-color: lightgray;
}

.panel-fields-title {
  width: 100%;
}

.docu-sign {
  div {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
}
.sign {
  border-bottom: 1px solid #000;
}

.dropdown-wrap {
  padding: 20px;
}

mat-form-field {
  i {
    color: #fc8016;
  }
}
